import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const LoginSchema = Yup.object().shape({
  email: Yup.string().required('email is required'),
  password: Yup.string().required('Password is required')
});

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.users.error);
  const [showPassword, setShowPassword] = useState(false);
  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      // we need to check here if the auth0_user has a app_user_id
      // this is now taken care in the navigation; simply redirect to authenticated
      if (user?.[`${process.env.REACT_APP_AUTH0_DOMAIN}/userId`]) {
        navigate('/dashboard/contacts');
      } else {
        navigate('/members/register');
      }
    }
  }, [isLoading, isAuthenticated]);

  return (
    <>
      <div className="max-w-md p-6 bg-white text-center mx-4">
        {isLoading ? (
          <p>Loading..</p>
        ) : (
          <>
            <img
              src="/logo192.png"
              alt="Ins CRM"
              className="rounded-md mx-auto mb-4 max-w-24"
            />
            <h1 className="text-2xl font-bold mb-4">Welcome to InsCRM.</h1>
            <p className="mb-8 text-gray-500">login to your account</p>
            <Button
              color="primary"
              variant="contained"
              onClick={() => loginWithRedirect()}
            >
              Login
            </Button>
            <div className="mt-4">
              <p className="text-slate-500">
                Dont have an account?{' '}
                <Button
                  onClick={() =>
                    loginWithRedirect({
                      authorizationParams: {
                        screen_hint: 'signup'
                      }
                    })
                  }
                >
                  Register
                </Button>
              </p>
            </div>
          </>
        )}
      </div>
      <ul className="flex justify-center my-6">
        <li className="mx-1 text-sm">
          <Link to="/privacy-policy" component={RouterLink}>
            Privacy Policy
          </Link>
        </li>
        <li className="mx-1 text-sm">
          <Link to="/terms-of-service" component={RouterLink}>
            Terms of Service
          </Link>
        </li>
        <li className="mx-1 text-sm">
          <Link to="/contact" component={RouterLink}>
            Contact us
          </Link>
        </li>
      </ul>
    </>
  );
};

export default Login;
