import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner/Spinner';
import { getPolicies } from 'features/contact/contactSlice';
import { useContacts } from 'hooks';
import { Filter, NewPolicy } from './components';
import { PolicyItem } from '../../components';

const Policies = () => {
  const { contacts } = useContacts();
  const policies = useSelector((state) => state.contacts.data.policies);
  const { contactId } = useParams();
  const dispatch = useDispatch();
  const showActive = useSelector(
    (state) => state.settings.policies.filter.showActive
  );

  useEffect(() => {
    if (contacts?.[contactId]?.policies?.status === 'idle') {
      dispatch(getPolicies(contactId))
        .unwrap()
        .catch((err) => {
          toast.error(`loading policies failed for ${contactId}` + err.message);
          console.error(err);
        });
    }
  });

  return (
    <div className="p-4">
      <div className="flex flex-wrap justify-between items-center mb-6">
        <div className="flex items-center">
          <h1 className="text-2xl font-bold me-2">Policies</h1>
          <Filter />
        </div>
        <NewPolicy />
      </div>

      {contacts?.[contactId]?.policies?.status === 'loading' && <Spinner />}
      {contacts?.[contactId]?.policies?.status === 'failed' && (
        <p className="text-red-500 mb-6">
          {contacts?.[contactId]?.policies?.error ||
            'There has been an error loading policies'}
        </p>
      )}

      {contacts?.[contactId]?.policies?.status === 'succeeded' && (
        <>
          {contacts[contactId].policies.list.length ? (
            <>
              {contacts[contactId].policies.list
                .filter((key) => {
                  if (showActive === true) {
                    if (policies[key].isActive === true) {
                      return key;
                    }
                  } else if (showActive === false) {
                    if (policies[key].isActive === false) {
                      return key;
                    }
                  } else {
                    return key;
                  }
                })
                .map((key) => (
                  <div className="border rounded p-4 mb-4" key={key}>
                    <PolicyItem policy={policies[key]} />
                  </div>
                ))}
            </>
          ) : (
            <p className="text-blue-400">
              <strong>0</strong> policies.
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default Policies;
