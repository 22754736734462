import React, { useState } from 'react';
import { parseJSON, format } from 'date-fns';
import clsx from 'clsx';
import { FormatDate, NewLineToBr } from 'components';
import { Button, IconButton, Badge } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { Link } from 'react-router-dom';
import { expirePolicy } from 'features/contact/contactSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { MovePolicy, DeletePolicy } from './components';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useContacts } from 'hooks';
import { getPolicyInsured } from 'utilities';

const PolicyItem = ({ policy }) => {
  const {
    id,
    policyNo,
    coverageFrom,
    primaryContact,
    coverageUpTo,
    policyType,
    isActive,
    make,
    model,
    year,
    plateNo,
    insurer,
    insured,
    address,
    notes,
    attachments,
    updatedAt
  } = policy;
  const [showActions, setShowActions] = useState(false);
  const dispatch = useDispatch();
  const { contacts } = useContacts();

  return (
    <>
      <p className="mb-3 text-sm text-slate-300">
        {format(parseJSON(updatedAt), 'yyyy-MM-dd HH:mm')}
      </p>

      <div className="flex items-center">
        <div className="me-2 sm:me-6 pe-2 sm:pe-6 border-r border-slate-50">
          <div className="flex mb-4">
            <div className="me-4">
              <p className="text-sm text-slate-500">Effective</p>
              <p className="text-slate-900">
                <FormatDate date={coverageFrom} />
              </p>
            </div>
            <div>
              <p className="text-sm text-slate-500">Expires</p>
              {parseJSON(coverageUpTo) < new Date() ? (
                <>
                  <p
                    className={clsx('font-medium', isActive && 'text-red-500')}
                  >
                    <FormatDate date={coverageUpTo} />
                  </p>
                  {isActive && (
                    <p className="text-uppercase text-blue-500 py-2">
                      <Button
                        size="small"
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          dispatch(
                            expirePolicy({ policyId: id, primaryContact })
                          )
                            .unwrap()
                            .then(() =>
                              toast.success(
                                'Policy successfully marked as expired.'
                              )
                            )
                            .catch((error) => {
                              console.error(error);
                              toast.error(
                                'There was an error marking policy as expired.'
                              );
                            });
                        }}
                      >
                        Mark Expired
                      </Button>
                    </p>
                  )}
                </>
              ) : (
                <p className="font-medium">
                  <FormatDate date={coverageUpTo} />
                </p>
              )}
            </div>
          </div>
          <p className="my-4">
            <span
              className={clsx(
                'text-sm',
                'text-white',
                'px-2',
                'py-1',
                'rounded',
                isActive ? 'bg-green-600' : 'bg-slate-500'
              )}
            >
              {policyType}
            </span>
          </p>
          <p>
            {insurer} {policyNo || ''}
          </p>
          {(() => {
            switch (policyType) {
              case 'auto':
                return (
                  <>
                    <p>
                      {make} {model} {year}
                    </p>
                    <p>
                      <span className="text-sm text-slate-500">plateNo:</span>{' '}
                      {plateNo}
                    </p>
                  </>
                );
              case 'storage':
                return (
                  <p>
                    {make} {model} {year}
                  </p>
                );
              case 'home':
                return (
                  <>
                    <p className="text-sm text-slate-500">Address</p>
                    <p>{address}</p>
                  </>
                );
              case 'visitor':
              case 'eo':
                return (
                  <>
                    <p className="text-sm text-slate-500">Insured</p>
                    <p>{insured}</p>
                  </>
                );
              default:
                return (
                  <p className="mb-4 text-red-500">Invalid policy type.</p>
                );
            }
          })()}
          {notes && (
            <div className="text-sm text-slate-500 my-2 max-w-96">
              <NewLineToBr text={notes} />
            </div>
          )}
        </div>
        {/* colMain*/}
        <div className="mb-2 tex-center">
          <IconButton
            color="primary"
            aria-label="View Images"
            component={Link}
            to={`/dashboard/contacts/${primaryContact}/policies/${id}/attachments`}
          >
            <Badge
              badgeContent={(attachments && attachments.length) || 0}
              color="secondary"
            >
              <ImageIcon />
            </Badge>
          </IconButton>
        </div>
        {/* colIcons*/}
      </div>

      {/* START ACTIONS */}
      <div className="mt-6 pt-4 border-t border-slate-50">
        {showActions ? (
          <ul className="md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-4">
            {isActive ? (
              <>
                <li>
                  <Button
                    variant="outlined"
                    color="success"
                    component={Link}
                    to={`/dashboard/contacts/${primaryContact}/policies/${policyType}/renew/${id}`}
                  >
                    Renew
                  </Button>
                  <p className="mt-2 mb-6 md:mb-0 text-sm text-slate-500">
                    A new policy will be created, marking this one dormant to
                    keep the history.
                  </p>
                </li>
                {/* renew */}

                <li>
                  <Button
                    variant="outlined"
                    component={Link}
                    to={`/dashboard/contacts/${primaryContact}/policies/${policyType}/update/${id}`}
                  >
                    Add/Alter
                  </Button>
                  <p className="mt-2 mb-6 md:mb-0 text-sm text-slate-500">
                    Use this option if the client has requested changes and you
                    wish to keep track of changes. For example: client has
                    requested to add a new driver to the policy.
                  </p>
                </li>
                {/* alter */}

                <li>
                  <Button
                    variant="outlined"
                    component={Link}
                    to={`/dashboard/contacts/${primaryContact}/policies/${id}/cancel`}
                  >
                    Cancel
                  </Button>
                  <p className="mt-2 mb-6 md:mb-0 text-sm text-slate-500">
                    Use cancel option if client has requested to cancel the
                    policy before expiry.
                  </p>
                </li>
                {/* cancel */}

                <li>
                  <MovePolicy policyId={id} contactId={primaryContact} />
                </li>
                {/* move */}

                <li>
                  <Button
                    variant="outlined"
                    color="error"
                    component={Link}
                    to={`/dashboard/contacts/${primaryContact}/policies/${policyType}/fix/${id}`}
                  >
                    Fix
                  </Button>

                  <p className="mt-2 text-sm text-red-500">
                    Caution: it will erase the history.
                  </p>
                  <p className="mb-6 md:mb-0 text-sm text-slate-500">
                    Use <strong>Fix</strong> option if you have accidentally
                    entered incorrect information and wants to fix that.
                  </p>
                </li>
                {/* fix */}

                <li>
                  <p className="mb-3 text-sm text-slate-500">
                    You must first <strong>CANCEL</strong> the policy.
                  </p>
                  <DeletePolicy
                    policyId={id}
                    contactId={primaryContact}
                    isDisabled={true}
                  />
                </li>
                {/* delete*/}
              </>
            ) : (
              <>
                {/* if policy isActive:false */}
                <li>
                  <MovePolicy policyId={id} contactId={primaryContact} />
                </li>
                {/* move */}

                <li>
                  <DeletePolicy
                    policyId={id}
                    contactId={primaryContact}
                    isDisabled={(attachments && attachments.length) || false}
                  />
                </li>
              </>
              /* delete*/
            )}
          </ul>
        ) : (
          <div className="flex flex-wrap items-center">
            <div className="me-2">
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setShowActions(true);
                }}
              >
                Actions
              </Button>
            </div>

            {contacts && contacts?.[primaryContact]?.cellNo && (
              <IconButton
                color="primary"
                aria-label="Send WhatsApp Notice"
                onClick={() => {
                  const message = encodeURIComponent(
                    `Dear ${contacts[primaryContact].firstName} ${
                      contacts[primaryContact].lastName
                    },

Hope you're doing well! I wanted to touch base regarding the renewal of your ${policyType} policy for ${getPolicyInsured(
                      policyType,
                      {
                        make,
                        model,
                        year,
                        address,
                        insured,
                        plateNo
                      }
                    )}. The renewal date is approaching on ${format(
                      parseJSON(coverageUpTo),
                      'MMM d, yyyy'
                    )}
Could you please confirm if you'd like to renew your policy? If so, could you let me know your preferred renewal date?

Feel free to reach out to me at 604-368-0001 with your decision or any questions you may have.

Looking forward to hearing from you soon.

Regards,
Rimpy Ghuman
Tel: (604) 368-0001
Email: rimpy@rimpyghuman.ca
Web: www.rimpyghuman.ca`
                  );
                  const url = `https://wa.me/1${contacts[primaryContact].cellNo}?text=${message}`;
                  //console.log(url);
                  window.open(url, '_blank');
                }}
              >
                <WhatsAppIcon />
              </IconButton>
            )}
          </div>
        )}
      </div>
      {/* actions */}
    </>
  );
};

export default PolicyItem;
